module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Welcome to Danos","short_name":"Danos","start_url":"/","background_color":"#00769e","theme_color":"#00769e","display":"minimal-ui","icon":"static/favicon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"07c8f48de05e64af7b3040e616db5af8"},
    },{
      plugin: require('../../gatsby-source-zms/plugins/gatsby-source-zms/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://www.danos.com","baseURL":"https://www.danos.com","models":["Content.Page","Foundation.Callout","Locations.Location","Masthead.Category","Foundation.Value","Services.Service","Services.Option","Foundation.Faq","Masthead.Slide","Press.Release","Staff.Gallery","About.Legacy","Events.Event","Content.Menu","Staff.Person","Blog.Author","Staff.Award","About.Pvvc","Blog.Topic","Staff.Team","Blog.Post","Story"],"pages":[{"type":"ContentPage","component":"/var/www/danos.com/src/templates/ContentPageGet/index.js"},{"type":"ServicesService","pageSize":1,"pagePattern":["/services/"],"overshoot":0,"listing":true,"component":"/var/www/danos.com/src/templates/ServicesServiceAll.js"},{"type":"ServicesService","component":"/var/www/danos.com/src/templates/ServicesServiceGet/index.js","path":"/services/$permalink/"},{"type":"BlogPost","pageSize":1,"pagePattern":["/danos-pipeline/"],"overshoot":0,"listing":true,"component":"/var/www/danos.com/src/templates/BlogPostAll/index.js"},{"type":"BlogPost","component":"/var/www/danos.com/src/templates/BlogPostGet/index.js","path":"/danos-pipeline/$permalink/"},{"type":"PressRelease","pageSize":1,"pagePattern":["/news/"],"overshoot":0,"listing":true,"component":"/var/www/danos.com/src/templates/NewsReportAll/index.js"},{"type":"PressRelease","component":"/var/www/danos.com/src/templates/NewsReportGet/index.js","path":"/news/$permalink/"},{"type":"EventsEvent","pageSize":1,"pagePattern":["/events/"],"overshoot":0,"listing":true,"component":"/var/www/danos.com/src/templates/EventsEventAll/index.js"},{"type":"EventsEvent","component":"/var/www/danos.com/src/templates/EventsEventGet/index.js","path":"/events/$permalink/"}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-redux/gatsby-browser.js'),
      options: {"plugins":[],"pathToCreateStoreModule":"./src/state/createStore.js","serialize":{"space":0,"isJSON":true,"unsafe":false},"cleanupOnClient":true,"windowKey":"__PRELOADED_STATE__"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
