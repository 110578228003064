/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import './src/styles/app.scss';
import './src/styles/slick.css';
import 'foundation-sites/js/foundation.js';
import 'whatwg-fetch';
// import $ from 'jquery';

export const onClientEntry = () => {
	// IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
	if (!(`IntersectionObserver` in window)) {
		return import(`intersection-observer`)
	}
}

export const onInitialClientRender = () => {
	// $(document).foundation();
}

export const onRouteUpdate = ({location, prevLocation}) => {
	window.dataLayer = window.dataLayer || [];
	window.dataLayer.push({'page': location.pathname, 'prevLocation': prevLocation ? prevLocation.pathname : null})
	try {
		var nodes = document.querySelectorAll('.QSIPopOver');
		nodes.forEach((node) => {
			node.remove();
		});
	}
	catch (e) {};
}

