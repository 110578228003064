import { createStore as reduxCreateStore } from "redux"

const initialState = {
	alert: true,
	terms: '',
	results: [],
	grid: {
		results: [],
		filterOptions: [],
		hasMore: false,
		useState: false,
		loadNum: 1,
		searchQuery: {},
		allEventData: [],
	}
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case 'ALERTMODE_ON':
			return { ...state, alert: true };
		case 'ALERTMODE_OFF':
			return { ...state, alert: false };

		case 'SET_SEARCH_RESULTS':
			return { ...state,
				terms: action.terms,
				results: action.results,
			};
		
		case 'SET_SEARCHGRID_RESULTS':
			return { ...state,
				grid: {
					...action
				},
			};

		default:
			return state;
	}
};

const rootReducer = (state, action) => {
	if (action.type === 'RESET') {
		const saveAlert = state.alert;
		state = initialState;
		state.alert = saveAlert;
	}
	return reducer(state, action);
}

const createStore = () => reduxCreateStore(rootReducer, initialState)
export default createStore